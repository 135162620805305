export const getClassesEmails = (params) => {
  const emailSubject = `${params.name}的課程預約 Has New Class Appointment Request`;
  const emailContent = `<p>您好 心靈易館：</p>
    <p>&nbsp;</p>
    <p>您收到了一個來自 ${params.name} 的<strong>${params.zhType}</strong>課程預約請求。以下是有關此次課程預約的詳細信息。請盡快聯繫客戶確認課程預約的日期和時間。&nbsp;</p>
    <ul>
      <li style="font-weight: bold;"><strong>客戶姓名：${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>電子郵件：${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>電話：${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>課程類型：${params.zhType}</strong></li>
    </ul>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;"><strong>給您的留言：</strong><br><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>祝好，</p>
    <p>EmailJS團隊</p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello SpiritualScience Institute,</p>
    <p>&nbsp;</p>
    <p>You got a new <strong>${params.enType}</strong> Class Appointment Request from ${params.name}. Below is the information regarding this class appointment. Please reach out to the client to confirm dates and times of the classes times and dates as soon as possible.</p>
    <ul>
      <li style="font-weight: bold;"><strong>Client name: ${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>Email: ${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>Phone: ${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>Class type: ${params.enType}</strong></li>
    </ul>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;"><strong>Message for you:</strong><br><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>Best wishes,<br>EmailJS team</p>
    <p>&nbsp;</p>
  `;

  const emailAutoReplySubject = `已收到您的課程預約 Your Class Appointment Request has been received`;
  const emailAutoReplyContent = `<p>您好 ${params.name}：</p>
    <p>&nbsp;</p>
    <p>您的<strong>${params.zhType}</strong>課程預約請求已成功發送。我們將儘快聯繫您確認課程預約的日期和時間。如有任何問題，請隨時聯繫我們。感謝您！</p>
    <p>您的聯絡資料：</p>
    <ul>
      <li><strong>姓名：${params.name}</strong></li>
      <li><strong>電子郵件：${params.email}</strong></li>
      <li><strong>電話：${params.phone}</strong></li>
      <li><strong>課程類型：${params.zhType}</strong></li>
    </ul>
    <p style={{ padding: '12px', borderLeft: '4px solid #d0d0d0', fontStyle: 'italic' }}><strong>留言：</strong><br /><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>祝好，</p>
    <p>心靈易館</p>
    <p><span style={{ fontSize: '10pt' }}>電子郵件：<a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello ${params.name},</p>
    <p>&nbsp;</p>
    <p>Your <strong>${params.enType}</strong> Class Appointment Request has successfully been sent. We will contact you to confirm the classes dates and times shortly. In the meantime, please feel free to reach out to us for any questions. Thank you!</p>
    <p>Your contact information:</p>
    <ul>
      <li style={{ fontWeight: 'bold' }}><strong>Name: ${params.name}</strong></li>
      <li style={{ fontWeight: 'bold' }}><strong>Email: ${params.email}</strong></li>
      <li style={{ fontWeight: 'bold' }}><strong>Phone: ${params.phone}</strong></li>
      <li style={{ fontWeight: 'bold' }}><strong>Class type: ${params.enType}</strong></li>
    </ul>
    <p style={{ padding: '12px', borderLeft: '4px solid #d0d0d0', fontStyle: 'italic' }}><strong>Your message:</strong><br /><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>Best wishes,<br />SpiritualScience Institute</p>
    <p><span style={{ fontSize: '10pt' }}>Email: <a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
  `;

  return {
    info: {
      subject: emailSubject,
      content: emailContent,
    },
    autoReplyInfo: {
      subject: emailAutoReplySubject,
      content: emailAutoReplyContent,
    },
  };
};
