export default {
  company: {
    title: "心靈易館",
  },
  header: {
    nav: {
      home: "主頁",
      about: {
        label: "關於我們",
        dropdown: {
          ourMission: "我們的使命",
          backgroundAndStory: "背景和故事",
        },
      },
      products: {
        label: "產品",
        dropdown: {
          allProducts: "所有產品",
          iChingCardsInstructions: "易經塔羅牌指南",
          iChingCardsExplanations: "易經塔羅牌解釋",
        },
      },
      services: {
        label: "服務",
        dropdown: {
          allServices: "所有服務",
          moreInfo: "更多關於我們的服務",
        },
      },
      classes: {
        label: "課程",
        dropdown: {
          allClasses: "所有課程",
          moreInfo: "更多關於我們的課程",
        },
      },
      testimonials: "見證",
      contact: "聯絡我們",
    },
  },
  footer: {
    subtitle: "© 2024 心靈易館 版權所有",
  },
  home: {
    hero: {
      title: "探索內在和諧",
      subtitle: "您是否曾經感到不幸的事情總是重複發生？透過個性化的玄學、心理學和整體療癒指導，改變您的生活。讓我們幫助您吸引正能量、克服挑戰，並探索通往持久幸福和滿足的道路。",
    },
    about: {
      title: "關於我們",
      subtitle: "擁抱靈性健康",
      content: {
        p1: "若想改變生活，首先必須改變您的想法和行為。唯有積極進行這些改變，才能迎接美好未來。",
        p2: "歡迎來到心靈易館，一個在香港，由Pallas Ng創辦的心靈療愈及風水實踐的聖地。我們致力於通過服務和產品來提升您的心靈旅程，推動和諧與平衡。",
      },
      button: "了解更多",
    },
    products: {
      title: "我們的產品",
      subtitle: {
        p1: "每個人擁有不同的能量，我們互相影響，產生新的能量，這直接影響我們的情緒、健康和運勢。",
        p2: "我們有獨特的居家方法，幫助您利用這種新能量來促進吉祥，並在生活的各個方面達致平衡。",
      },
      loadMore: {
        button: "載入更多",
      },
    },
    services: {
      title: "服務",
      readMore: {
        button: "閱讀更多",
      },
      bookNow: {
        button: "立即預約",
      },
    },
    classes: {
      title: "課程",
      subtitle: "好奇如何操作？來學習一下吧",
    },
    testimonials: {
      title: "見證",
      subtitle: "心靈易館提供的服務徹底改變了我的生活，我找到了前所未有的內心平靜和和諧。",
    },
    contactUs: {
      title: "聯絡我們",
      phoneNumber: "+852 4414 2989",
      email: "cs@ichingspiritual.com",
      website: "https://www.ichingspiritual.com",
      location: "香港",
    },
  },
  shared: {
    productGrid: {
      quickViewBanner: "快速查看",
      addToCartButton: "加入購物車",
    },
    quickViewModal: {
      quantityLabel: "數量",
      addToCartButton: "加入購物車",
      viewDetailsLink: "查看更多詳情",
    },
  },
  products: {
    iChingCardsInstructions: {
      title: "易經塔羅牌指南",
      about: {
        title: "關於易經塔羅牌",
        content: {
          p1: "很多人發現尋求傳統風水大師進行風水諮詢非常困難，常需要提前預約並且費用昂貴...",
          p2: "每個人擁有不同的能量，在共同的環境中，我們互相影響，產生新的能量，這被稱為風水能量，直接影響我們的情緒、健康、事業和運勢。",
          p3: "現在，我們引入了一種利用易經塔羅牌和能量燈的方法，讓您可以自我調整風水能量，促進吉祥，並在生活的各個方面達致平衡。這種方法既高效又經濟。",
          p4: "透過我們的易經塔羅牌和能量燈，您可以輕鬆地提升居住或工作環境的風水能量，從而改善生活質素。",
        },
      },
      instructions: {
        title: "如何使用易經塔羅牌",
        content: {
          p1: "易經塔羅牌包含64個卦象，可用於各種範疇的諮詢，從日常事務到年度預測。",
          p2: "占卜範疇：居家與辦公室風水能量、人際關係、工作與事業、財運、內心、學習、人生方向、旅行、健康。",
        },
      },
      steps: {
        title: "步驟",
        1: "選擇卡片布局。",
        2: "在洗牌時冥想問題，並設想未來3到12個月內的可能情景。",
        3: "洗牌完成後，直觀地抽牌並按順序放在布局中的位置上。抽牌過程中專注於問題的畫面。",
        4: "將抽出的牌放置於桌上，根據卡片的布局解讀問題的答案。參考“易經塔羅牌解釋”指南幫助解讀。",
        5: "若答案不滿意，可再抽一張牌作為解決方案。易經塔羅牌不分好壞，只分適合與否。",
        p1: "請注意，易經塔羅牌無法回答封閉式問題，避免'是否'、'好壞'等問題。應專注於開放式問題，如'如何處理…'、'未來可能的發展是什麼？'、'此事的情況是什麼？'",
      },
      cardSpread: {
        title: "易經塔羅牌佈局",
        oneCardDivination: {
          title: "單牌占卜法",
          content: "適合獲取隨機靈感。",
        },
        twoCardDivination: {
          title: "雙牌占卜法",
          content: {
            header: "適合預測兩個階段的情景或比較兩種情況。",
            p1: "示例1：當前情況和未來發展。",
            p2: "示例2：公司A與公司B的發展比較。",
          },
        },
        threeCardDivination: {
          title: "三卡占卜法",
          content: {
            li1: "適合預測三個階段的情景或比較三種情況。",
            li2: "適合預測問題、原因及結果的情境。",
            p1: "示例1：現在、過去、未來。",
            p2: "示例2：在國家A、B和C的個人事業發展前景。",
            p3: "示例3：當前問題、原因及結果。",
          },
        },
        fengShuiDivination: {
          title: "風水能量占卜法",
          content: {
            li1: "抽取九張牌，代表居家或辦公室的平面佈局。",
            li2: "選擇一張牌代表主入口，其餘放置於相應方位。",
            li3: "如某一方向的能量為負面，可抽一張補救牌，並將此補救牌與能量燈放置於相應位置，改變風水能量。",
            li4: "將解決方案卡和能量燈放置在您實際生活或工作空間中的相應位置，以啟動風水能量的變化。",
          },
        },
        astrologyDivination: {
          title: "中國占星占卜法",
          content: {
            li1: "適合預測未來十二個月的各方面運勢。",
            li2: {
              main: "抽取十二張牌，每張代表十二個領域之一的運勢。",
              life: "命宮：整體個人運勢及突發事件。",
              parents: "父母宮：與父母及長輩的關係。",
              psychic: "心靈宮：心理狀態、心理健康及內在修養。",
              residence: "居住宮：個人資產、生活與工作環境。",
              career: "事業宮：個人工作和與上司的互動。",
              friends: "朋友宮：與下屬及朋友的關係及狀況。",
              travel: "遷移宮：個人外在形象及旅行、搬遷的吉凶。",
              health: "健康宮：個人健康及內臟功能強弱。",
              wealth: "財帛宮：財運及資金流動。",
              children: "子女宮：子女及/或寵物的情況。",
              spouse: "夫妻宮：配偶的狀況及/或關係動態。",
              siblings: "兄弟宮：兄弟的狀況及/或與伴侶的互動。",
            },
            li3: "選擇一張牌代表“命宮”，可以考慮當前的時間或月份。",
            li4: "根據十二宮的分佈分配抽取的牌。請參考下方的圓形圖表以分配十二宮位置。",
            li5: "若抽到不吉利的牌，可再抽一張補救牌，請注意需使用完整牌組進行補救。",
          },
        },
      },
      nextStepsGuide: {
        text: "關於易經塔羅牌的解釋及建議，請訪問",
        link: "這裡。",
      },
    },
    category: {
      iChingCards: {
        header: "什麼是易經塔羅牌",
        howTo: {
          header: "如何使用易經塔羅牌",
        }
      },
      subconsciousIntegration: {
        about: {
          header: "什麼是根源能量重塑",
          content: {
            p1: "Pallas的根源能量重塑，幫助您與平行宇宙和解 ✨，吸引您想要的東西進入生活。它可以治療身體疾病、過去的創傷、情感和心理模式、關係問題、商業問題等‼️",
            p2: "無論您想改變什麼，它都可以幫助您快速輕鬆地實現 🌈。",
          },
        },
        demo: {
          header: "根源能量重塑示範",
          content: "好奇這是如何完成的嗎？查看下面的示範並試著與您的潛意識溝通。",
        },
      },
      singingBowls: {
        header: "看看如何進行頌缽療愈",
      },
      spiritualCommunication: {
        header: "聆聽他人對靈性溝通的看法",
      },
    },
    iChingCardsExplanations: {
      title: "易經塔羅牌解釋及建議",
      subTitle: "查看每張牌的代表意義及如何在日常生活中幫助您",
      overview: "諸事",
      relationships: "愛情",
      career: "事業",
      wealth: "財運",
      advice: "建議",
      search: {
        placeholder: "按牌號搜索",
        errorMessage: "找不到此牌。請輸入1到64之間的數字",
      },
    },
  },
  productDetailsPage: {
    productQuantity: {
      label: "數量：",
    },
    addToCartBtn: "加入購物車",
    productInfo: {
      header: "產品資訊",
    },
    shippingInfo: {
      header: "配送資訊",
      content: "香港本地配送訂單滿500港元可享免費送貨。訂單低於500港元，需收取20港元的運費。如需要運到其他地方，請聯繫我們了解詳情。",
    },
  },
  detailViewPage: {
    contactDetails: {
      title: "聯絡資訊",
      phone: "+852 4414 2989",
      email: "cs@ichingspiritual.com",
      location: "香港",
    },
  },
  classCard: {
    bookNow: {
      button: "立即預約",
    },
    viewCourse: {
      button: "立即預約",
    },
  },
  about: {
    backgroundAndStory: {
      preTitle: "我們的",
      title: "背景和故事",
      brandStory: {
        title: "品牌故事",
        content: {
          p1: "從小，我對玄學抱有濃厚的興趣。除了沉迷於金庸及言情小說，我的閱讀重心也集中在玄學和心理學的書籍上。年輕時，我不善於表達自己的感受，常常因為不滿而默默生氣，行為上也顯得偏激。然而，這一切在我前往紐西蘭求學後開始改變。",
          p2: "一次偶然的機會，我讀到了《心靈雞湯》，這本書讓我意識到自己與家人之間的價值觀存在差異，這些差異影響了我的心理狀態和行為模式。玄學吸引我，因為它能幫助我理解人生的劇本；而心理學則提供了針對當前問題的解決方案，幫助我反思和調整自己的價值觀及行為。能量的概念則將這兩者串聯起來，形成一個完整的理解框架。",
          p3: "因此，我創立了心靈易館，致力於將左腦的邏輯思維與右腦情感領域的靈魂連結，這使我成為今天的「靈魂科學家」。通過這個平台，我希望能引導每一位參與者找到屬於自己的獨特幸福，並有效地面對人生課題。",
        },
      },
      logoBackgroundStory: {
        content: {
          title: "公司標誌背景由來",
          p1: "我們的公司標誌由兩個獨特的能量圖案組成，象徵著我們的核心價值與願景。",
          p2: {
            bold: "豐盛 (Abundance):",
            text: "這個圖案由八個交織的無限符號構成，象徵著人生的豐盛與無限的可能性。每一個無限符號代表著持續的成長和繁榮，提醒我們珍惜生活中的每一份富饒。",
          },
          p3: {
            bold: "卓越 (Excellent):",
            text: "另一個能量符號由三個相互連結的「E」組成，象徵著良好的人際關係與美好的人緣。這個設計強調了互相支持與和諧交流的重要性，展現出我們對建立積極關係的承諾。",
          },
          p4: "這兩個圖案共同構成了我們的品牌形象，傳遞出追求豐盛人生與卓越連結的理念。",
        },
      },
    },
  },
  aboutUsPage: {
    preTitle: "關於我們的",
    title: "故事和使命",
    subtitle: "每個人都是能量的集合體。在我們的日常生活中，我們會遇到他人的能量，這些能量會相互影響，改變我們對世界的看法。理解這些能量有助於保持積極心態並收穫生活中的最佳成就。",
    ourMission: {
      title: "我們的使命",
      content: {
        p1: "Pallas創立心靈易館，旨在幫助每個人理解並增強他們的能量。我們的使命是讓每個人都能找到屬於自己的幸福和滿足。",
        p2: "作為一名玄學家，我的角色是幫助您吸引好運並趨吉避凶，指引您發掘自己內在的最大潛力，為美好生活鋪路。作為心理顧問，我可以支持您面對生活挑戰，與內在和解，找到最適合的生活態度。作為心靈指導，我將協助您提升自我意識，讓您的靈魂實現圓滿與幸福。通過八字、玄空飛星風水、易經占卜、靈性溝通、根源能量重塑及頌缽療愈等技術，我旨在幫助您深入了解自己，開啟人生的新道路。",
      },
    },
    aboutPallas: {
      title: "關於Pallas",
      content: {
        p1: "Pallas畢業於澳洲新南威爾士大學，主修心理學。在其19年的職業生涯中，曾任職於國泰航空及其他物流公司擔任高層管理職位。擁有超過14年的靈性溝通與命理諮詢經驗，專精於：",
        list: {
          item1: "八字",
          item2: "風水",
          item3: "手相",
          item4: "面相",
          item5: "易經 / 占卜",
          item6: "根源能量重塑",
          item7: "內在小孩靈性溝通",
          item8: "頌缽療愈",
        },
      },
    },
  },
  productsPage: {
    header: {
      title: "所有產品",
      subtitle: "通過我們的產品，提升您的能量、增進和諧並創造豐盛的生活。我們的產品為您的每一步提供智慧和平衡。",
    },
  },
  servicesPage: {
    header: {
      title: "我們的服務",
    },
    serviceDetails: {
      bookNow: {
        button: "立即預約",
      },
    },
  },
  services: {
    moreServicesInfo: {
      title: "更多關於我們的服務",
      subTitle: "聽聽創辦人對我們的服務的分享",
      content: {
        subconsciousIntegration: {
          header: "根源能量重塑",
          intro: {
            caption: "服務介紹：幫助您與平行宇宙和解 ✨",
          },
          caseSharing: {
            caption: "案例分享：釋放身體負能量 👍🏻，效果顯著而迅速！",
          },
        },
        singingBowls: {
          header: "頌缽療愈",
          caption: "看看如何進行頌缽療愈",
        },
      },
    },
  },
  classesPage: {
    header: {
      title: "我們的課程",
      subtitle: "曾經疑惑命理占卜是如何運作的嗎？來親自學習吧！我們的課程可以無限次複課，提供您所需的各種指導。",
    },
    timetable: {
      caption: {
        pContent1: "請查看我們的",
        anchorText: "Facebook專頁",
        pContent2: "以獲取最新的課堂時間表。",
      },
    },
  },
  classDetailsPage: {
    bookNow: {
      button: "立即預約",
    },
  },
  classes: {
    moreClassesInfo: {
      title: "更多關於我們的課程",
      subTitle: "聽聽我們的創辦人對課程的介紹",
      content: {
        spiritualCommunication: {
          header: "靈性溝通",
          caseSharing: {
            caption: "案例分享：靈性溝通不僅可以與您的寵物交流，也可以與他人交流。",
          },
        },
      },
    },
  },
  testimonialsPage: {
    header: {
      title: "見證",
      subtitle: "「聽聽其他人在他們的靈性旅程中所分享的經驗」",
    },
  },
  contactUsPage: {
    header: {
      title: "聯絡我們",
      subtitle: "我們在這裡陪伴您的旅程！無論您有問題、需要指導，還是想了解更多我們的產品，隨時與我們聯繫。讓我們一起連接並成長！",
    },
    successToaster: "您的請求已成功提交至心靈易館！",
    errorToaster: "您的提交出現錯誤。請稍後再試。",
    form: {
      name: "姓名",
      email: "電郵",
      phoneNumber: "電話號碼",
      phoneNumberHelperText: "如非香港地區，請加上地區碼",
      message: "信息",
      submitButton: "發送",
    },
  },
  checkoutPage: {
    contactInfo: {
      header: {
        title: "聯絡資訊",
      },
    },
    form: {
      name: {
        label: "姓名",
        placeholder: "輸入您的姓名",
        errorMessage: "姓名為必填項目",
      },
      email: {
        label: "電郵",
        placeholder: "輸入您的電郵",
        errorMessage: "電郵地址為必填項目",
      },
      phone: {
        label: "電話號碼",
        placeholder: "輸入您的電話號碼",
        errorMessage: "電話號碼為必填項目",
      },
      address: {
        label: "地址",
        placeholder: "輸入您的地址",
        errorMessage: "地址為必填項目",
      },
    },
    payment: {
      header: {
        title: "付款方式",
        instruction: {
          p1: "請在24小時內通過PayMe或FPS向我們付款，以確保您的訂單。",
          p2a: "付款後，請",
          p2b: "截圖或拍照作為付款證明",
          p2c: "，以確認訂單。",
        },
      },
      payMe: {
        title: "PayMe",
      },
      fps: {
        title: "FPS",
        details: "公司FPS號碼: 112381983",
      },
      alipay: {
        title: "支付寶 / 微信支付",
      },
    },
    orderSummary: {
      title: "訂單",
      qty: "數量",
      totalAmount: "總計",
    },
    confirmOrderBtn: "下單",
  },
  orderConfirmationPage: {
    header: {
      title: "感謝您的訂單！",
    },
    content: {
      p1: "您的訂單已成功提交。",
      p2: "請記得支付款項，以便盡快收到您的貨品。",
    },
    returnToHome: "返回主頁",
  },
  orderCancellationPage: {
    header: {
      title: "抱歉...出現了問題",
    },
    content: {
      p1: "我們處理您的訂單時遇到了技術問題。",
      p2: "請通過WhatsApp或電郵與我們聯繫下單。不便之處敬請見諒。",
    },
    returnToHome: "返回主頁",
  },
  shoppingCart: {
    header: {
      title: "購物車",
    },
    removeItemBtn: "移除",
    emptyCart: {
      message: "您的購物車暫時沒有產品。",
    },
    totalAmount: "總計",
    checkoutBtn: "前往結帳",
  },
  servicesAppointmentPage: {
    header: {
      title: "預約您的服務",
    },
    form: {
      name: {
        label: "姓名",
        placeholder: "輸入您的姓名",
      },
      email: {
        label: "電郵",
        placeholder: "輸入您的電郵",
      },
      phone: {
        label: "電話",
        placeholder: "輸入您的電話號碼",
      },
      apptType: {
        label: "服務類型",
      },
      message: {
        label: "信息",
        caption: "請告訴我們您想約這項服務的日期和時間，以及您希望我們了解的任何信息。我們會盡快聯繫您確認預約。",
      },
    },
    disclaimer: {
      message: "當您點擊'發送'時，即表示您同意我們的條款和條件。",
    },
    sendBtn: "發送",
  },
  servicesApptConfirmationPage: {
    header: {
      title: "感謝您的服務預約！",
    },
    content: {
      p1: "我們會盡快聯繫您確認預約日期和時間。",
      p2: "如有任何疑問，請隨時與我們聯繫。",
    },
    returnBtn: "返回服務頁面",
  },
  servicesApptCancellationPage: {
    header: {
      title: "抱歉...出現了問題",
    },
    content: {
      p1: "我們在處理您的服務預約時遇到技術問題。",
      p2: "請通過電子郵件或WhatsApp與我們聯繫預約。不便之處敬請見諒。",
    },
    returnBtn: "返回服務頁面",
  },
  classesAppointmentPage: {
    header: {
      title: "預留您的課程位置",
    },
    form: {
      name: {
        label: "姓名",
        placeholder: "輸入您的姓名",
      },
      email: {
        label: "電郵",
        placeholder: "輸入您的電郵",
      },
      phone: {
        label: "電話",
        placeholder: "輸入您的電話號碼",
      },
      apptType: {
        label: "課程類型",
      },
      message: {
        label: "信息",
        caption: "請告訴我們您有空的日期和時間，以及您希望我們了解的任何信息。我們會盡快聯繫您確認課程時間。",
      },
    },
    disclaimer: {
      message: "當您點擊'發送'時，即表示您同意我們的條款和條件。",
    },
    sendBtn: "發送",
  },
  classesApptConfirmationPage: {
    header: {
      title: "感謝您對我們課程的興趣！",
    },
    content: {
      p1: "我們會盡快聯繫您確認課程時間。",
      p2: "如有疑問，請隨時與我們聯繫。",
    },
    returnBtn: "返回課程頁面",
  },
  classesApptCancellationPage: {
    header: {
      title: "抱歉...出現了問題",
    },
    content: {
      p1: "我們處理您的課程預約時遇到技術問題。",
      p2: "請通過電子郵件或WhatsApp與我們聯繫預約課程。不便之處敬請見諒。",
    },
    returnBtn: "返回課程頁面",
  },
};
