import React from 'react';
import ClassCard from '../../shared/ClassCard/ClassCard';
import './ClassesPage.css';
import { useTranslation } from '../../contexts/TranslationContext';

const ClassesPage = () => {
  const { translate, getConstants } = useTranslation();
  const classesData = getConstants('classesData');

  return (
    <div className="classes-page">
      <div className='classes-page-header'>
        <h1>{translate('classesPage.header.title')}</h1>
        <p>{translate('classesPage.header.subtitle')}</p>
      </div>
      <div className='classes-timetable-container'>
        <p>
          {translate('classesPage.timetable.caption.pContent1')}
          <a href="https://www.facebook.com/profile.php?id=61552984856445">
            {translate('classesPage.timetable.caption.anchorText')}
          </a>
          {translate('classesPage.timetable.caption.pContent2')}
        </p>
        <div className="facebook-embed-wrapper">
          <iframe
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid027zA3G7RUTD94JjGD3MUYHtfAvB9aribYWup2KQAyschuZNYvsbpbS8PohJvCA8fZl%26id%3D61552984856445&show_text=true&width=500"
            className="facebook-embed"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
      <div className="classes-page-list">
        {classesData.map((classItem, index) => (
          <ClassCard key={index} classData={classItem} isDisplayOnHover={false} />
        ))}
      </div>
    </div>
  );
};

export default ClassesPage;
