export const getContactUsEmails = (params) => {
  const emailSubject = `客戶查詢 ${params.name} Inquiries from ${params.name}`;
  const emailContent = `<p>您好 心靈易館：</p>
    <p>&nbsp;</p>
    <p>您收到了一則來自 ${params.name} 的新訊息，請盡快回覆客戶：</p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      客戶姓名: ${params.name}&nbsp;
    </p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      客戶電子郵件: ${params.email}
    </p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      客戶電話號碼: ${params.phone}
    </p>
    <p style="padding-top: 12px; padding-right: 12px; padding-bottom: 12px; border-left: 4px solid rgb(208, 208, 208); font-style: italic;">
      &nbsp; 留言內容: ${params.message}&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>祝好，<br>EmailJS 團隊</p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello SpiritualScience Institute,</p>
    <p>&nbsp;</p>
    <p>You got a new message from ${params.name}. Please reply the client as soon as possible:</p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      Name of Client: ${params.name}&nbsp;
    </p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      Email of Client: ${params.email}
    </p>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
      Phone number of Client: ${params.phone}
    </p>
    <p style="padding-top: 12px; padding-right: 12px; padding-bottom: 12px; border-left: 4px solid rgb(208, 208, 208); font-style: italic;">
      &nbsp; Message to you: ${params.message}&nbsp;&nbsp;
    </p>
    <p>Best wishes,<br>EmailJS team</p>
    <p>&nbsp;</p>
  `;

  const emailAutoReplySubject = `已收到您的查詢 Your inquiry has been received`;
  const emailAutoReplyContent = `<p>您好 ${params.name}：</p>
    <p>&nbsp;</p>
    <p>感謝您聯絡我們。<br>我們將盡快回覆您。</p>
    <p>您的訊息如下：</p>
    <blockquote>
      <p>姓名：${params.name}</p>
      <p>電子郵件：${params.email}</p>
      <p>電話號碼：${params.phone}</p>
      <p>留言內容：${params.message}</p>
    </blockquote>
    <p>&nbsp;</p>
    <p>此致<br>心靈易館</p>
    <p><span style={{ fontSize: '10pt' }}>電子郵件：<a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello ${params.name},</p>
    <p>&nbsp;</p>
    <p>Thank you for contacting us.</p>
    <p>We will reply to you soon.</p>
    <p>Your message as follows:</p>
    <blockquote>
      <p>Name: ${params.name}</p>
      <p>Email: ${params.email}</p>
      <p>Phone: ${params.phone}</p>
      <p>Message: ${params.message}</p>
    </blockquote>
    <p>&nbsp;</p>
    <p>Best regards,<br>SpiritualScience Institute</p>
    <p><span style={{ fontSize: '10pt' }}>Email: <a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <pre>&nbsp;</pre>
  `;

  return {
    info: {
      subject: emailSubject,
      content: emailContent,
    },
    autoReplyInfo: {
      subject: emailAutoReplySubject,
      content: emailAutoReplyContent,
    },
  };
};
